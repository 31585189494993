<template>
  <div class="amap-page-container">
    <el-amap
      vid="amapDemo"
      :center="center"
      :events="events"
      :amap-manager="amapManager"
      :zoom="zoom"
      class="amap-demo"
    >
    </el-amap>
    <div class="switch" style="overflow: hidden; margin-top: 10px; padding: 0px 70px">
      <div style="float: right">
        <span>自动播放:</span>
        <el-switch
          v-model="stop"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="switchChange"
        >
        </el-switch>
      </div>
    </div>
    <div class="timeLine">
      <div class="cur" v-for="(item, index) in times" :key="index">
        <!--<div class="radius"-->
        <!--:class="index===23 ? 'noAfter' : ''" @click="timeClick(index)"></div>-->
        <div
          class="radius"
          :class="[{ classred: index == current }, { noAfter: index === 23 ? 'noAfter' : '' }]"
          @click="timeClick(index)"
        ></div>
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
let timeList = [];
for (let i = 0; i < 24; i++) {
  i < 10 ? timeList.push("0" + i + ":00") : timeList.push(i + ":00");
}
export default {
  data() {
    return {
      zoom: 13,
      center: [116.369172, 39.991598],
      amapManager,
      map: null,
      heatmap: null,
      times: timeList,
      stop: true,
      current: 0,
      timer: "",
      isAutoPlay: true,
      index: 0,
      events: {
        init: (o) => {
          setTimeout(() => {
            // this.map.setCity(this.cityCode)
            this.heatMapInit();
          }, 1000);
        },
      },
    };
  },
  // watch: {
  //   cityCode(val) {
  //     if (val) {
  //          this.city()
  //     }
  //   }
  // },
  props: ["parkList", "cityCode"],
  methods: {
    city() {
      setTimeout(() => {
        if (this.cityCode > 0) {
          this.map.setCity(this.cityCode);
        }
      }, 2000);
    },
    timeClick(index) {
      this.current = index;
      this.getHeatMap();
    },
    autoChange() {
      this.timer = setInterval(() => {
        this.current++;
        if (this.current == 23) {
          this.current = 0;
        }
        this.getHeatMap();
        // this.map.setCity('0558')
      }, 1000);
    },
    switchChange() {
      if (this.stop) {
        this.autoChange();
      } else {
        clearInterval(this.timer);
      }
    },
    heatMapInit() {
      if (!this.isSupportCanvas()) {
        // alert("热力图仅对支持canvas的浏览器适用,您所使用的浏览器不能使用热力图功能,请换个浏览器试试~")
      }
      var map = amapManager.getMap();
      this.map = map;
      var heatmap;
      this.map.plugin(["AMap.Heatmap"], () => {
        // 初始化heatmap对象
        heatmap = new AMap.Heatmap(this.map, {
          radius: 35, // 给定半径
          opacity: [0, 0.8],
          gradient: {
            0.5: "blue",
            0.65: "rgb(117,211,248)",
            0.7: "rgb(0, 255, 0)",
            0.9: "#ffea00",
            1.0: "red",
          },
        });
        this.heatmap = heatmap;
      });
    },
    getHeatMap() {
      let points = [];
      this.parkList[this.current].forEach((e, index) => {
        // console.log(Number(e.lon), "888888")
        let point = {
          lng: Number(e.lon),
          lat: Number(e.lat),
          count: e.occupyRate * 1000 * 100,
        };
        points.push(point);
      });
      this.heatmap.setDataSet({
        data: points,
        max: 1000,
      });
    },
    // 判断浏览区是否支持canvas
    isSupportCanvas() {
      var elem = document.createElement("canvas");
      return !!(elem.getContext && elem.getContext("2d"));
    },
  },
  mounted() {
    // this.center = sessionStorage.center.split(",")
    this.autoChange();
    this.city();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="stylus" scoped>
.amap-page-container {
  top: 70px;

  .amap-demo {
    // padding 0 60px;
    height: 500px;
  }
}

.timeLine {
  display: flex;
  width: 1160px;
  margin: 0 auto;
  justify-content: space-around;
  margin-top: 10px;

  .cur {
    display: inline-block;
    cursor: pointer;

    .radius {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #999;
      margin: 0 auto;
      margin-bottom: 5px;

      &:after {
        display: inline-block;
        position: relative;
        top: -6px;
        left: 11px;
        content: '';
        width: 35px;
        height: 0px;
        border: 1px solid #999;
      }
    }

    .noAfter {
      &:after {
        border: 0px;
      }
    }
  }
}

.classred {
  background: #0099FA;
}

>>>.amap-page-container {
  height: 300px;
}
</style>
