<template>
  <div class="parkingIs content">
    <!-- <div class="searchWrapper"> -->
    <!-- <el-form :inline="true"
               label-position="right"
               :model="formInline"
               class="demo-form-inline">
        <el-form-item :label="$t('searchModule.date')">
          <el-date-picker v-model="value1"
                          type="datetime"
                          placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary"
                   class="searchBtn"
                   icon="el-icon-search"
                   @click="page=1;searchData()"
                   :loading="loading">{{ $t('button.search') }}</el-button>
      </el-form> -->
    <!-- </div> -->
    <div style="height: 600px; position: relative">
      <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
        <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <a-cascader ref="cascader" @change="parkClear"></a-cascader>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="areaId" filterable @change="getCityCode" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="area.areaName"
              :value="area.areaId"
              :key="area.areaId"
              v-for="area in areaList"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item :label="$t('searchModule.Belonging_operator')">
          <a-operation-select ref="operationSelect"></a-operation-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
          <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
        </el-form-item> -->
        <el-form-item :label="$t('searchModule.date')">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-button
          type="primary"
          class="searchBtn"
          icon="el-icon-search"
          @click="
            page = 1;
            searchData();
          "
          :loading="loading"
          style="margin-top: 6px"
          >{{ $t('button.search') }}</el-button
        >
        <!-- {{ cityCode }} -->
      </el-form>
      <!-- <div class='bigger'>
          <img src='./fullscreen.png' @click="viewmap" style="width:100%;height:100%">
        </div> -->
      <!-- 左下角示例图 -->
      <div class="text-content">
        <div><b>颜色对应车位空闲情况</b><span style="float: right" id="date"></span></div>
        <div style="padding-top: 3px">
          <img src="./Rectangle .png" style="border-radius: 2px" width="100%" height="4px" />
        </div>
        <div class="percent">
          <span class="no-mar">50%</span>
          <span>60%</span>
          <span>70%</span>
          <span>90%</span>
          <span>100%</span>
        </div>
      </div>
      <!-- 地图 -->
      <heat-map ref="map" :parkList="parkList" :cityCode="cityCode"></heat-map>
    </div>
    <div class="berthFullEchart graphShadow paddingT10 paddingLR20" v-if="echshow">
      <berth-full-echart
        :nightParktableData="nightParktableData"
        :newtime="newtime"
        ref="echart"
      ></berth-full-echart>
    </div>
  </div>
</template>

<script>
// import autoComplete from '@/mycomponents/myautocomplete';
import heatMap from "./heatMap";
import berthFullEchart from "./berthFullEchart";
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "parkingIs",
  components: {
    heatMap,
    berthFullEchart,
  },
  data() {
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let dateTime = date;
    return {
      setCode: "",
      cityCode: "",
      areaId: "",
      areaList: [],
      echshow: true,
      newtime: 0,
      formInline: {
        date: dateTime,
      },
      loading: false,
      nightParktableData: [],
      parkList: [],
      bigSmall: 1,
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let three = 7 * 24 * 3600 * 1000;
          let threeMonths = curDate + three;
          return time.getTime() > threeMonths;
        },
      },
    };
  },
  methods: {
    parkClear() {
      // areaCode
      // this.cityCode = this.$refs.cascader.getAreaCode();
      // this.$refs.parkSelect.clear();
      console.log(this.cityCode, "this.cityCodethis.cityCodethis.cityCode");
    },
    getCityCode() {
      for (let code in this.areaList) {
        if (this.areaList[code].areaId == this.areaId) {
          // console.log("code-->", this.areaList[code].areaCode);
          this.setCode = this.areaList[code].areaCode;
        }
      }
    },
    areaChange(value) {
      // /acb/2.0/area/{areaId}
      // let url = '/acb/2.0/area/?id='  + value
      if (value) {
        let url = "/acb/2.0/area";
        this.$axios
          .post(url, {
            data: {
              id: value,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.setCode = res.value.areaCode;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      } else {
        this.setCode = "";
      }
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
            // this.map.setCity(this.areaList[0].areaCode)
            // this.cityCode = res.value.areaList[0].areaCode
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // viewmap () {
    //   if (this.bigSmall == 1) {
    //     let mapDom = this.$refs.map.$el;
    //     mapDom.className = "amap-demo";
    //     mapDom.style.height = "100%";
    //     this.echshow = false;
    //     this.bigSmall = 2;
    //   } else {
    //     let mapDom = this.$refs.map.$el;
    //     mapDom.className = "amap-demo";
    //     mapDom.style.height = "500px";
    //     this.echshow = true;
    //     this.bigSmall = 1;
    //   }
    // },
    parkAnalysis() {
      let url = "/acb/2.0/parkPredict/heatmap/" + this.dayTime;
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkList = res.value;
          }
        });
    },
    heatLinechart(time) {
      let timeA = this.formInline.date;
      var current = new Date();
      var start = new Date(current);
      start.setHours(0, 0, 0, 0);
      var end = new Date(current);
      end.setHours(23, 59, 59, 999);
      // if (timeA > new Date().getTime()) {
      //   this.newtime = 2;
      // } else {
      //   this.newtime = 1;
      // }
      // newTime : 1：昨天及以前，2：今天，3：明天及以后
      if (timeA > end.getTime()) {
        this.newtime = 3;
      } else if (timeA < start.getTime()) {
        this.newtime = 1;
      } else {
        this.newtime = 2;
      }
      let url = "/acb/2.0/heatMapStatistics/heatmaptrend";
      this.$axios
        .get(url, {
          data: {
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            dataDate: time,
          },
        })
        .then((res) => {
          console.log("回家客户", time);

          if (res.state == 0) {
            this.nightParktableData = res.value;
          }
        });
    },
    searchData() {
      // if (this.cityCode) {
      // this.cityCode = this.setCode;
      console.log(this.$refs.cascader.getAreaCode(), "this.$refs.cascader.getAreaCode()");
      if (this.$refs.cascader.getAreaCode()) {
        this.cityCode = this.$refs.cascader.getAreaCode();
      } else {
        this.cityCode = "110108";
      }
      this.areaId = this.$refs.cascader.getAreaId();
      // } else {
      // 亳州行政区码
      // this.cityCode = "110108";
      // }
      console.log(this.cityCode, "this.cityCodethis.cityCodethis.cityCode");
      this.$refs.map.city();
      let timeOne = this.formInline.date;
      let year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      let day = timeOne.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      var Timedata = year + "-" + month + "-" + day;
      this.heatLinechart(Timedata);
      let url = "/acb/2.0/heatMapStatistics/heatMap";
      // this.loading = true;
      // console.log(this.areaId)
      console.log(this.$refs.cascader ? this.$refs.cascader.getAreaCode() : "", "222222");
      this.$axios
        .get(url, {
          data: {
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            dataDate: Timedata,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: "",
            // dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
          },
        })
        .then((res) => {
          this.parkList = res.value;
        });
    },
  },
  mounted() {
    this.searchData();
    this.getAreaList();
  },
};
</script>

<style lang="stylus" scoped>
.parkingIs .searchWrapper .el-form >>> .el-form-item
  margin-bottom 0px
.parkingIs
  .searchWrapper
    padding 5px
    background-color #eee
    margin-bottom 15px
  .berthFullEchart
    position relative
    bottom 0px
    width 100%
    height 300px
    margin-top 60px
.demo-form-inline
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 5;
.text-content{
  line-height: 21px;
  font-size: 15px;
  padding: 10px;
  color: white;
  position: absolute;
  right: 24px;
  background: rgba(85, 85, 62, 0.6);
  padding-bottom:0;
  width: 456px;
  height: 78px;
  z-index: 5;
  bottom: 100px;
}
.text-content .percent span{
  margin-left: 66px;
}
.no-mar{
  margin:0 !important;
}
.bigger {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 100px;
  right: 30px;
  cursor: pointer;
  z-index: 500;
}
</style>
