<template>
  <div class="recordGraph">
    <div id="circularChart" class="circularChart"></div>
    <graphTitle class="title" :title="text"></graphTitle>
  </div>
</template>
<script>
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";

export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return {};
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
    newtime: {
      default: function () {
        return {};
      },
    },
    type: {
      default: "c3",
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [],
      // 夜间停车 （以下）
      nightParknameList: [],
      nightParkdataList: [],
      // 营收总报表（以下）
      paySummaryReportdataList: [],
      seriesValue1: [
        {
          name: "应收总额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "欠费额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "red",
          },
          itemStyle: {
            normal: {
              color: "red",
            },
          },
        },
      ],
      seriesValue2: [
        {
          name: "当日临停次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      seriesValue3: [
        {
          name: "实际饱和度",
          type: "line",
          symbol: "none", // 是否去除线条上的小圆点
          data: [],
          lineStyle: {
            color: "#1CB1F2",
          },
          itemStyle: {
            normal: {
              color: "#1CB1F2",
            },
          },
        },
        {
          name: "预测饱和度",
          type: "line",
          symbol: "none", // 是否去除线条上的小圆点
          data: [],
          lineStyle: {
            color: "yellow",
          },
          itemStyle: {
            normal: {
              color: "yellow",
              lineStyle: {
                type: "dashed", // dotted'虚线 'dashed'宽虚线 'solid'实线
                color: "yellow",
              },
            },
          },
        },
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle1: "欠费金额趋势",
      textTitle2: "过去30日临时停车趋势",
      // textTitle3: '日间/夜间停车时长趋势',
      textTitle3: "平均车场饱和度",
      textTitle4: "历史营收趋势",
      text: "",
    };
  },
  watch: {
    tableData: {
      handler(value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1;
          this.nameList.push(value[i].dataDate);
          this.dataList.push({
            name: value[i].name,
            value: Number(value[i].value / 100).toFixed(2),
          });
        }
        this.totalNum = (this.totalNum / 100).toFixed(2);
        this.drawGraph();
      },
      deep: true,
    },
    // 欠费历史分析（欠费金额趋势）
    arrearageHistorytableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue1[0].data = [];
        this.seriesValue1[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 应收金额
          this.seriesValue1[0].data.push(Number(value[i].shouldPay / 100).toFixed(2));
          // 欠费额
          this.seriesValue1[1].data.push(Number(value[i].arrearageAmount / 100).toFixed(2));
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 临时停车统计（过去30日临时停车趋势）
    temporaryParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue2[0].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 实测数据
          if (value[i].actual) {
            this.seriesValue2[0].data.push(value[i].shortParkNum * 100);
          }
          // 预测数据
          if (value[i].prediction) {
            this.seriesValue2[1].data.push(value[i].prediction.shortParkNum * 100);
          }
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        console.log(this.newtime, "88888888");
        this.totalNum = 0;
        this.nightParknameList = [];
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        // this.totalNum += value[i].value * 1
        // this.dateList.push(value[i].date);
        var serial;
        // newTime : 1：昨天及以前，2：今天，3：明天及以后
        if (value.actual && this.newtime == 1) {
          // 实际数据
          for (var a = 0; a < value.actual.length; a++) {
            serial = (Number(value.actual[a].berthRate) * 100).toFixed(2);
            serial = serial < 0 ? 0 : serial > 100 ? 100 : serial;
            this.seriesValue3[0].data.push(serial);   
          }
        }
        if (this.newtime == 2) {
          // 实际数据
          if (value.actual) {
            for (var d = 0; d < value.actual.length; d++) {
              serial = (Number(value.actual[d].berthRate) * 100).toFixed(2);
              serial = serial < 0 ? 0 : serial > 100 ? 100 : serial;
              this.seriesValue3[0].data.push(serial);
            }
          }
          // 预测数据
          if (value.prediction) {
            for (var b = 0; b < value.prediction.length; b++) {
              serial = (Number(value.prediction[b].berthRate) * 100).toFixed(2);
              serial = serial < 0 ? 0 : serial > 100 ? 100 : serial;
              this.seriesValue3[1].data.push(serial);
              // this.seriesValue3[1].data.push(Number(value.prediction[b].berthRate).toFixed(2))
            }
          }
        }
        if (value.prediction && this.newtime == 3) {
          // 预测数据
          for (var c = 0; c < value.prediction.length; c++) {
            serial = (Number(value.prediction[c].berthRate) * 100).toFixed(2);
            serial = serial < 0 ? 0 : serial > 100 ? 100 : serial;
            this.seriesValue3[1].data.push(serial);
            // this.seriesValue3[1].data.push(Number(value.prediction[c].berthRate).toFixed(2))
          }
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 营收总报表的营收趋势
    paySummaryReporttableData: {
      handler(value) {
        this.totalNum = 0;
        let dateArr = [];
        this.dateList = [];
        this.seriesValue4[0].data = [];
        for (var i = 0; i < value.length; i++) {
          // this.totalNum += value[i].value * 1
          dateArr.push(value[i].date);
          this.seriesValue4[0].data.push(Number(value[i].amount / 100).toFixed(2));
        }
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById("circularChart"));
      let option = {
        // title: {
        //   text: that.type == "c1" ? that.textTitle1 : that.type == "c2" ? that.textTitle2 : that.type == "c3" ? that.textTitle3 : that.textTitle4
        // },
        legend: {
          data:
            that.type == "c1"
              ? ["应收总额", "欠费额"]
              : that.type == "c2"
              ? ["当日临停次数"]
              : that.type == "c3"
              ? ["实际饱和度", "预测饱和度"]
              : ["历史营收趋势"],
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let str = "";
            str += params[0].axisValue + "<br/>";
            params.forEach((e) => {
              str += e.marker + `${e.seriesName}:${e.value}%<br/>`;
            });
            return str;
          },
        },
        toolbox: {
          show: true,
          feature: {
            //		            magicType: {show: true, type: ['stack', 'tiled']},
            //		            saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: this.dateList
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          name: "饱和度",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            formatter: "{value} %", // 左侧Y轴文字显示
            textStyle: {
              color: "blank",
            },
          },
        },
        series:
          that.type == "c1"
            ? that.seriesValue1
            : that.type == "c2"
            ? that.seriesValue2
            : that.type == "c3"
            ? that.seriesValue3
            : that.seriesValue4,
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {
    this.text =
      this.type == "c1"
        ? this.textTitle1
        : this.type == "c2"
        ? this.textTitle2
        : this.type == "c3"
        ? this.textTitle3
        : this.textTitle4;
  },
  components: {
    graphTitle,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.recordGraph {
  position: relative;
}

.circularChart {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.title {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
