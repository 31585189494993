var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "amap-page-container" },
    [
      _c("el-amap", {
        staticClass: "amap-demo",
        attrs: {
          vid: "amapDemo",
          center: _vm.center,
          events: _vm.events,
          "amap-manager": _vm.amapManager,
          zoom: _vm.zoom,
        },
      }),
      _c(
        "div",
        {
          staticClass: "switch",
          staticStyle: {
            overflow: "hidden",
            "margin-top": "10px",
            padding: "0px 70px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { float: "right" } },
            [
              _c("span", [_vm._v("自动播放:")]),
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                },
                on: { change: _vm.switchChange },
                model: {
                  value: _vm.stop,
                  callback: function ($$v) {
                    _vm.stop = $$v
                  },
                  expression: "stop",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "timeLine" },
        _vm._l(_vm.times, function (item, index) {
          return _c("div", { key: index, staticClass: "cur" }, [
            _c("div", {
              staticClass: "radius",
              class: [
                { classred: index == _vm.current },
                { noAfter: index === 23 ? "noAfter" : "" },
              ],
              on: {
                click: function ($event) {
                  return _vm.timeClick(index)
                },
              },
            }),
            _vm._v(" " + _vm._s(item) + " "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }